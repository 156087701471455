<template>

  <div class="content_container">

    <div
      class=" border"
      style="margin-top:20px;        background: #fff;
"
    >
      <p class="graybg">物流信息</p>

      <div class="logistics">

        <div class="cont">
          <p style="font-size:16px;font-weight:bold;margin-bottom:20px">{{info.get_shipping.shipping_name}} </p>
          <p>物流单号： {{info.shipping_code}}</p>

          <el-button
            type="info"
            style="margin-top:20px"
            @click="toRescue
          "
            block
            shape="circle"
          >
            查看详情
          </el-button>
        </div>
        <el-divider></el-divider>






        <table class="list">
          <tr><td>运单：</td><td>{{ info.out_request_no}}</td></tr>
             <tr><td>卖家： </td><td> {{info.shop_name}} </td></tr>
         
           <tr>
            <td>收货地址</td>
            <td>{{info.get_address.address}}</td>
           
          </tr>
          <tr>
            <td>收货人</td> 
            <td>{{info.get_address.receiver}}</td>
           
          </tr>
           <tr>
            
            <td>电话</td>
            <td>{{info.get_address.tel}}</td>
          </tr>
           <tr>
            
            <td>产品</td>
            <td><img :src="$config.baseUrl+info.get_order_product[0].files_path" style="width:100px;height:100px">
            <p>{{info.get_order_product[0].price}}x {{info.get_order_product[0].quantity}}</p>
            </td>
          </tr>
        </table>

      </div>

    </div>

  </div>
</template>
<script>
export default {
  data() {
    return {
      name,
      current: 3,
      titles: [],
      current2: 0,
      url: "",
      info: {}
    };
  },
  components: {},

  watch: {},
  methods: {
    toRescue() {
      window.location.href = this.url;
    },
    getDetail() {
      this.$get("seller/order/" + this.$route.query.order_id, {
        shipping_code: this.$route.query.shipping_code,
        shipping_method: this.$route.query.shipping_method
      }).then(res => {
        console.log("res.result;", res.result);
        this.info = res.result;
         
          this.url='https://www.baidu.com/s?wd='+ res.result.get_shipping.shipping_name + res.result.shipping_code
      });
    }
  },

  created() {
    this.getDetail();
    // this.$get("admin/shipping").then(res => {
    //   console.log("res", res);
    //   res.items.find(item => {
    //     if (item.shipping_id == this.$route.query.shipping_method) {
    //       this.name = item.shipping_name;
    //       this.code = this.$route.query.shipping_code;
    //       this.url = "https://www.baidu.com/s?wd=" + this.name + this.code;
    //     }
    //   });
    // });
  }
};
</script>
<style scoped lang="scss">
@import "@/assets/styles/mixins";
.list td{
  padding: 10px;
}
.cont {
  text-align: center;
}
.logistics {
  padding: 20px;
}
.graybg {
  background: #eee;

  padding: 10px;
}
</style>
